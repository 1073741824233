import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  packs: { count: 0, results: [] },
  packsParams: { limit: 10, offset: 0 },

  products: [],
  productsParams: { limit: 10, offset: 0 },
  productsSelection: [],

  productCategories: [],
  productsCategoriesParams: { limit: 10, offset: 0 },
  downloadedProductCategories: [],
  productCategoryFields: [],

  productTags: [],
  productsTagsParams: { limit: 10, offset: 0 },
  downloadedProductTags: [],
  productTagsFields: [],

  productStatus: [],
  productType: [],
  productFields: [],
  downloadedProducts: [],

  productPlans: [],
  productPlansParams: { limit: 10, offset: 0 },
  downloadedProductPlans: [],

  productOrderFrequency: [],
  productPlanningPolicy: [],
  productStorageMethod: [],
  productReOrderPlanMethod: [],
  productAcquisitionCode: [],
  productStockLocation: [],
  productPlanFields: [],

  productAlias: [],
  productAliasParams: { limit: 10, offset: 0 },
  productAliasFields: [],
  downloadedProductAlias: [],

  productsBasedSupplier: [],
  productsBasedWareCosting: [],
  productsBasedFromWarehouse: [],
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    createMultipleProductAlias: (state, action) => {
      state.productAlias.count += action.payload.length;
      state.productAlias.results = [...state.productAlias.results, ...action.payload];
    },
    createMultipleProductCategories: (state, action) => {
      state.productCategories.count += action.payload.length;
      state.productCategories.results = [...state.productCategories.results, ...action.payload];
    },
    createMultipleProductPlan: (state, action) => {
      state.productPlans.count += action.payload.length;
      state.productPlans.results = [...state.productPlans.results, ...action.payload];
    },
    createMultipleProducts: (state, action) => {
      state.products.count += action.payload.length;
      state.products.results = [...state.products.results, ...action.payload];
    },
    createMultipleProductTags: (state, action) => {
      state.productTags.count += action.payload.length;
      state.productTags.results = [...state.productTags.results, ...action.payload];
    },
    createProduct: (state, action) => {
      state.products.count += action.payload.length;
      state.products.results = [...state.products.results, action.payload];
    },
    createProductAlias: (state, action) => {
      state.productAlias.count += action.payload.length;
      state.productAlias.results = [...state.productAlias.results, action.payload];
    },
    createProductCategory: (state, action) => {
      state.productCategories.count += action.payload.length;
      state.productCategories.results = [...state.productCategories.results, action.payload];
    },
    createProductPlan: (state, action) => {
      state.productPlans.count += action.payload.length;
      state.productPlans.results = [...state.productPlans.results, action.payload];
    },
    createProductTag: (state, action) => {
      state.productTags.count += action.payload.length;
      state.productTags.results = [...state.productTags.results, action.payload];
    },
    getDownloadedProductAlias: (state, action) => {
      state.downloadedProductAlias = action.payload;
    },
    getDownloadedProductCategories: (state, action) => {
      state.downloadedProductCategories = action.payload;
    },
    getDownloadedProductPlans: (state, action) => {
      state.downloadedProductPlans = action.payload;
    },
    getDownloadedProducts: (state, action) => {
      state.downloadedProducts = action.payload;
    },
    getDownloadedProductTags: (state, action) => {
      state.downloadedProductTags = action.payload;
    },
    getProductAcquisitionCode: (state, action) => {
      state.productAcquisitionCode = action.payload;
    },
    getProductAlias: (state, action) => {
      state.productAlias = action.payload;
    },
    getProductAliasFields: (state, action) => {
      state.productAliasFields = action.payload;
    },
    getProductBasedWareCosting: (state, action) => {
      state.productsBasedWareCosting = action.payload;
    },
    getProductCategories: (state, action) => {
      state.productCategories = action.payload;
    },
    getProductCategoryFields: (state, action) => {
      state.productCategoryFields = action.payload;
    },
    getProductFields: (state, action) => {
      state.productFields = action.payload;
    },
    getProductOrderFrequency: (state, action) => {
      state.productOrderFrequency = action.payload;
    },
    getProductPlanFields: (state, action) => {
      state.productPlanFields = action.payload;
    },
    getProductPlanningPolicy: (state, action) => {
      state.productPlanningPolicy = action.payload;
    },
    getProductPlans: (state, action) => {
      state.productPlans = action.payload;
    },
    getProductReOrderPlanMethod: (state, action) => {
      state.productReOrderPlanMethod = action.payload;
    },
    getProducts: (state, action) => {
      state.products = action.payload;
    },
    getProductsBasedFromWarehouse: (state, action) => {
      state.productsBasedFromWarehouse = action.payload;
    },
    getProductsBasedSupplier: (state, action) => {
      state.productsBasedSupplier = action.payload;
    },
    getProductStatus: (state, action) => {
      state.productStatus = action.payload;
    },
    getProductStockLocation: (state, action) => {
      state.productStockLocation = action.payload;
    },
    getProductStorageMethod: (state, action) => {
      state.productStorageMethod = action.payload;
    },
    getProductTags: (state, action) => {
      state.productTags = action.payload;
    },
    getProductTagsFields: (state, action) => {
      state.productTagsFields = action.payload;
    },
    getProductType: (state, action) => {
      state.productType = action.payload;
    },
    resetPacks: state => {
      state.packs = initialState.packs;
    },
    resetProductAcquisitionCode: state => {
      state.productAcquisitionCode = initialState.productAcquisitionCode;
    },
    resetProductAlias: (state, action) => {
      state.productAlias = initialState.payload;
    },
    resetProductAliasParams: state => {
      state.productAliasParams = { limit: state.productAliasParams.limit, offset: 0 };
    },
    resetProductBasedWareCosting: (state, action) => {
      state.productsBasedWareCosting = initialState.productsBasedWareCosting;
    },
    resetProductCategories: state => {
      state.productCategories = initialState.productCategories;
    },
    resetProductOrderFrequency: state => {
      state.productOrderFrequency = initialState.productOrderFrequency;
    },
    resetProductPlanningPolicy: state => {
      state.productPlanningPolicy = initialState.productPlanningPolicy;
    },
    resetProductPlans: (state, action) => {
      state.productPlans = initialState.payload;
    },
    resetProductPlansParams: state => {
      state.productPlansParams = { limit: state.productPlansParams.limit, offset: 0 };
    },
    resetProductReOrderPlanMethod: state => {
      state.productReOrderPlanMethod = initialState.productReOrderPlanMethod;
    },
    resetProducts: (state, action) => {
      state.products = initialState.products;
    },
    resetProductsBasedFromWarehouse: (state, action) => {
      state.productsBasedFromWarehouse = initialState.productsBasedFromWarehouse;
    },
    resetProductsBasedSupplier: (state, action) => {
      state.productsBasedSupplier = initialState.productsBasedSupplier;
    },
    resetProductsCategoriesParams: state => {
      state.productsCategoriesParams = { limit: state.productsCategoriesParams.limit, offset: 0 };
    },
    resetProductsParams: state => {
      state.productsParams = { limit: state.productsParams.limit, offset: 0 };
    },
    resetProductsSelection: state => {
      state.productsSelection = initialState.productsSelection;
    },
    resetProductsTagsParams: state => {
      state.productsTagsParams = { limit: state.productsTagsParams.limit, offset: 0 };
    },
    resetProductStatus: state => {
      state.productStatus = initialState.productStatus;
    },
    resetProductStockLocation: state => {
      state.productStockLocation = initialState.productStockLocation;
    },
    resetProductStorageMethod: state => {
      state.productStorageMethod = initialState.productStorageMethod;
    },
    resetProductTags: state => {
      state.productTags = initialState.productTags;
    },
    resetProductType: state => {
      state.productType = initialState.productType;
    },
    setPacks: (state, action) => {
      const { count, results } = action.payload;
      state.packs = { ...state.packs, count, results };
    },
    setPacksParams: (state, action) => {
      state.packsParams = action.payload;
    },
    setProductAliasParams: (state, action) => {
      state.productAliasParams = action.payload;
    },
    setProductPlansParams: (state, action) => {
      state.productPlansParams = action.payload;
    },
    setProductsCategoriesParams: (state, action) => {
      state.productsCategoriesParams = action.payload;
    },
    setProductsParams: (state, action) => {
      state.productsParams = action.payload;
    },
    setProductsSelection: (state, action) => {
      state.productsSelection = action.payload;
    },
    setProductsTagsParams: (state, action) => {
      state.productsTagsParams = action.payload;
    },
    updateMultipleProductAlias: (state, action) => {
      const products = action.payload;
      products.forEach(product => {
        const index = state.productAlias.results.findIndex(item => item.id === product.id);
        if (index !== -1) {
          state.productAlias.results[index] = { ...state.productAlias.results[index], ...product };
        }
      });
    },
    updateMultipleProductCategories: (state, action) => {
      const productCategories = action.payload;
      productCategories.forEach(product => {
        const index = state.productCategories.results.findIndex(item => item.id === product.id);
        if (index !== -1) {
          state.productCategories.results[index] = {
            ...state.productCategories.results[index],
            ...product,
          };
        }
      });
    },
    updateMultipleProductPlans: (state, action) => {
      const productPlans = action.payload;
      productPlans.forEach(product => {
        const index = state.productPlans.results.findIndex(item => item.id === product.id);
        if (index !== -1) {
          state.productPlans.results[index] = { ...state.productPlans.results[index], ...product };
        }
      });
    },
    updateMultipleProducts: (state, action) => {
      const products = action.payload;
      products.forEach(product => {
        const index = state.products.results.findIndex(item => item.id === product.id);
        if (index !== -1) {
          state.products.results[index] = { ...state.products.results[index], ...product };
        }
      });
    },
    updateMultipleProductTags: (state, action) => {
      const productTags = action.payload;
      productTags.forEach(product => {
        const index = state.productTags.results.findIndex(item => item.id === product.id);
        if (index !== -1) {
          state.productTags.results[index] = { ...state.productTags.results[index], ...product };
        }
      });
    },
    updateProduct: (state, action) => {
      const index = state.products.results.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.products.results[index] = { ...state.products.results[index], ...action.payload };
      }
    },
    updateProductAlias: (state, action) => {
      const index = state.productAlias.results.findIndex(file => file.id === action.payload.id);
      if (index !== -1) {
        state.productAlias.results[index] = {
          ...state.productAlias.results[index],
          ...action.payload,
        };
      }
    },
    updateProductCategory: (state, action) => {
      const index = state.productCategories.results.findIndex(
        item => item.id === action.payload.id,
      );
      if (index !== -1) {
        state.productCategories.results[index] = {
          ...state.productCategories.results[index],
          ...action.payload,
        };
      }
    },
    updateProductPlan: (state, action) => {
      const index = state.productPlans.results.findIndex(file => file.id === action.payload.id);
      if (index !== -1) {
        state.productPlans.results[index] = {
          ...state.productPlans.results[index],
          ...action.payload,
        };
      }
    },
    updateProductTag: (state, action) => {
      const index = state.productTags.results.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.productTags.results[index] = {
          ...state.productTags.results[index],
          ...action.payload,
        };
      }
    },
  },
});

export const {
  createMultipleProductAlias,
  createMultipleProductCategories,
  createMultipleProductPlan,
  createMultipleProducts,
  createMultipleProductTags,
  createProduct,
  createProductAlias,
  createProductCategory,
  createProductPlan,
  createProductTag,
  getDownloadedProductAlias,
  getDownloadedProductCategories,
  getDownloadedProductPlans,
  getDownloadedProducts,
  getDownloadedProductTags,
  getProductAcquisitionCode,
  getProductAlias,
  getProductAliasFields,
  getProductBasedWareCosting,
  getProductCategories,
  getProductCategoryFields,
  getProductFields,
  getProductOrderFrequency,
  getProductPlanFields,
  getProductPlanningPolicy,
  getProductPlans,
  getProductReOrderPlanMethod,
  getProducts,
  getProductsBasedFromWarehouse,
  getProductsBasedSupplier,
  getProductStatus,
  getProductStockLocation,
  getProductStorageMethod,
  getProductTags,
  getProductTagsFields,
  getProductType,
  resetPacks,
  resetProductAcquisitionCode,
  resetProductAlias,
  resetProductAliasParams,
  resetProductBasedWareCosting,
  resetProductCategories,
  resetProductOrderFrequency,
  resetProductPlanningPolicy,
  resetProductPlans,
  resetProductPlansParams,
  resetProductReOrderPlanMethod,
  resetProducts,
  resetProductsBasedFromWarehouse,
  resetProductsBasedSupplier,
  resetProductsCategoriesParams,
  resetProductsParams,
  resetProductsSelection,
  resetProductsTagsParams,
  resetProductStatus,
  resetProductStockLocation,
  resetProductStorageMethod,
  resetProductTags,
  resetProductType,
  setPacks,
  setPacksParams,
  setProductAliasParams,
  setProductPlansParams,
  setProductsCategoriesParams,
  setProductsParams,
  setProductsSelection,
  setProductsTagsParams,
  updateMultipleProductAlias,
  updateMultipleProductCategories,
  updateMultipleProductPlans,
  updateMultipleProducts,
  updateMultipleProductTags,
  updateProduct,
  updateProductAlias,
  updateProductCategory,
  updateProductPlan,
  updateProductTag,
} = productSlice.actions;

export default productSlice.reducer;
