import clsx from "clsx";

/**
 * FormFieldError
 * Displays an error message for form fields like input, select, or radio buttons.
 *
 * @param {string} message - The error message to be displayed.
 */
const FormFieldError = ({ message, className = "" }) =>
  message && (
    <div className={clsx("fv-plugins-message-container", className)}>
      <div className="fv-help-block">
        <span role="alert">{message}</span>
      </div>
    </div>
  );

export { FormFieldError };
